import NeoFormBuilder from "@shared/components/form-builder";
import axios from "@/axios";
import { delviumLogo } from "@/plugins/common";
import Loader from "@shared/components/loader"
import { mapGetters } from "vuex";
import { setFavIcon } from "@shared/utils/functions"
export default {
    name: 'candidate-form',
    components: {
        NeoFormBuilder,
        Loader
    },
    data() {
        return {
            data: null,
            gapErrors: {},
            userInfo: {
                first_name: "First name",
                last_name: "Last name",
                email: "Subject email",
                phone: "Subject phone number"
            },
            delviumLogo,
            logo:'',
            isCandidate:false,
        }
    },
    async mounted() {
        let logo_data = localStorage.getItem('themeObj');
        this.logo = JSON.parse(logo_data).logo_url;
        setFavIcon(this.logo);

        await this.fetchForm()
        // await this.formFake()
        // if(sessionStorage.getItem('role')=='Subject'){this.isCandidate=true;}
    },
    computed : {
        ...mapGetters(['getThemeLogo'])
    },
    methods: {

        async formFake(){
            const url = "/simulate/form";
            try {

                const data = await axios.get(url)
                this.data = {"sections":data.data}
            }
            catch(error){
                console.warn(error)
            }
        },
         
        async fetchForm(){
            // Remove hardcoded screening package id(for debugging purposes only)
            const package_id = this.$route.params.package;
            const role = this.$route.query.role;
            const entity_type = this.$route.query.entity_type;
            const url = `/screening-package/${package_id}/${entity_type}/form`
            try{
                const data = await axios.get(url, {
                    params: { role }
                })
                // this.data = data.data.sections
                this.data = data.data
            }catch(error){
                console.error("[+] error in fetching form data")
            }
        },
        saveForm(payload) {
            console.log('> save form: ', payload);
        },
    }
}


